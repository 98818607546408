import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import style from './index.module.scss'
import JobGrid from './JobGrid'
import JobStatistic from '../JobStatistic/JobStatistic'
import { getTab, updateRcTab } from '@lib/rc-dock-lib'
import { getSize } from '@utils/getSize'
import TabClose from '@components/TabClose'
import CronJobDetail from './CronJobDetail'

const JobLayout = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const [jobStatus, setJobStatus] = useState<IJob.Status>('Landed')
  const jobGridRef = useRef<any>(null)

  const fetchCronJob = () => {
    if (jobGridRef.current) {
      jobGridRef.current.fetchJobs()
    }
  }

  const getTabData = (cronJobData: any) => {
    const tabId = 'tabs' + cronJobData._id

    return {
      id: tabId,
      title: (
        <>
          <div className='user-tab-header'>
            <span>{cronJobData.name}</span>
          </div>
          <TabClose dockLayoutRef={dockLayoutRef} id={tabId} />
        </>
      ),
      content: <CronJobDetail cronJobData={cronJobData} jobStatus={jobStatus} fetchCronJob={fetchCronJob} />,
      group: 'close-all'
    }
  }

  const handleCronJobDetail = (cronJobData: any) => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const _dockLayout = dockLayoutRef.current

      if (_dockLayout.state.layout.maxbox?.children) {
        const { children } = _dockLayout.state.layout.maxbox

        if (children.length > 0) {
          const panelData = _dockLayout.find('jobList')
          _dockLayout.dockMove(panelData as any, null, 'maximize')
        }
      }

      if (!_dockLayout.find('tabs' + cronJobData._id)) {
        if (_dockLayout.find('cronJobDetail')) {
          _dockLayout.dockMove(getTabData(cronJobData), 'cronJobDetail', 'middle')
        }
      } else {
        // updating the same tab
        _dockLayout.updateTab('tabs' + cronJobData._id, getTabData(cronJobData))
      }
      handleCronJobDetailDisplay('block')
    }
  }

  useEffect(() => {
    if (dockLayoutRef.current) {
      updateRcTab(
        dockLayoutRef,
        'jobList',
        getTab('jobList', jobStatus + ' Job', dockLayoutRef, () => (
          <JobGrid handleCronJobDetail={handleCronJobDetail} jobStatus={jobStatus} ref={jobGridRef} />
        ))
      )
    }
  }, [dockLayoutRef, jobStatus, jobGridRef])

  useEffect(() => {
    if (dockLayoutRef.current) {
      updateRcTab(
        dockLayoutRef,
        'jobs',
        getTab('jobs', '', dockLayoutRef, () => <JobStatistic jobStatus={jobStatus} setJobStatus={setJobStatus} />)
      )
    }
  }, [dockLayoutRef])

  useEffect(() => {
    handleCronJobDetailDisplay('none')
  }, [])

  const handleCronJobDetailDisplay = useCallback((state: string) => {
    const manageProfilePanel = document.querySelector('[data-dockid="cronJobDetail"]') as HTMLElement
    if (manageProfilePanel) {
      manageProfilePanel.style.display = state
    }
  }, [])

  const cronJobSection = useMemo(
    () => [
      {
        mode: 'horizontal',
        children: [
          {
            id: 'usersSection',
            tabs: [
              {
                id: 'jobList',
                title: 'Landed Jobs',
                content: <></>,
                group: 'headless'
              }
            ],
            group: 'close-all'
          },
          {
            id: 'cronJobDetail',
            panelLock: true,
            tabs: [],
            group: 'close-all'
          }
        ]
      }
    ],
    [dockLayoutRef]
  )

  const defaultLayout: any = useMemo(
    () => ({
      dockbox: {
        mode: 'vertical',
        children: [
          {
            size: getSize(2600, 1600),
            tabs: [
              {
                id: 'jobs',
                title: 'Jobs',
                content: <></>,
                group: 'headless'
              }
            ]
          },
          {
            mode: 'horizontal',
            children: cronJobSection as any
          }
        ]
      },
      floatbox: {
        children: []
      }
    }),
    [cronJobSection]
  )

  const handleLayoutChange = useCallback(async (layout?: any) => {
    const handleTabsCheck = () => {
      const _dockLayout = dockLayoutRef.current

      if (_dockLayout?.find('cronJobDetail')) {
        const { tabs } = _dockLayout?.find('cronJobDetail') as any
        if (tabs && tabs.length === 0) {
          if (layout.maxbox?.children[0] && layout.maxbox?.children[0].id === 'cronJobDetail') {
            const panelData = _dockLayout.find('cronJobDetail')
            _dockLayout.dockMove(panelData as any, null, 'maximize')
          } else {
            setTimeout(() => {
              handleCronJobDetailDisplay('none')
            }, 0)
          }
        }
      }
    }

    // Execute handleTabsCheck on the next animation frame
    requestAnimationFrame(handleTabsCheck)
  }, [])
  return (
    <div className={`${style.layoutContainer} mt-2`}>
      <RCDockLayout onLayoutChange={handleLayoutChange} dockLayoutRef={dockLayoutRef} defaultLayout={defaultLayout} />
    </div>
  )
}
export default JobLayout
