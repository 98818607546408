import { CronJobResponseDto } from '@digitalworkflow/dwairportclient'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import style from './index.module.scss'
import { jobService } from '@services/index'
import moment from 'moment'
import { toast } from 'react-toastify'

interface ICronJobDetail {
  cronJobData: CronJobResponseDto
  jobStatus: string
  fetchCronJob: () => void
}

const CronJobDetail = ({ cronJobData, jobStatus, fetchCronJob }: ICronJobDetail) => {
  const [jobDetail, setJobDetail] = useState<any>()
  const [disableLoading, setDisableLoading] = useState<boolean>(false)

  useEffect(() => {
    getJobDetail(cronJobData.jobHandlerId)
  }, [cronJobData])

  const getJobDetail = useCallback(async (handlerId: string) => {
    const result: any = await jobService.getJobByHandlerId(handlerId)
    if (!result.is_error) setJobDetail(result.data)
  }, [])

  const duration = useMemo(() => {
    const startTime = moment.utc(cronJobData.startedAt)
    const endTime = moment.utc(cronJobData.endedAt)

    const duration = moment.duration(endTime.diff(startTime))

    // Format the duration as needed
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    const parts = []
    if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`)
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`)

    return parts.join(', ')
  }, [cronJobData])

  const handleDisableJob = useCallback(async () => {
    setDisableLoading(true)
    const result: any = await jobService.cancelJobByHandlerId(cronJobData.jobHandlerId)
    if (!result.is_error) {
      fetchCronJob()
      toast.success('Job disabled successfully')
    }
    setDisableLoading(false)
  }, [cronJobData, setDisableLoading, fetchCronJob])

  return (
    <div className={`p-2 ${style.cronJobDetail}`}>
      <div className='d-flex justify-content-between flex-wrap gap-2'>
        <div className='d-flex align-items-center gap-2'>
          <span className={style.executionId}>{cronJobData.executionId}</span>
          <div className={style.bdage}>{jobStatus}</div>
        </div>
        {(jobStatus === 'Scheduled' || jobStatus === 'Pending') && (
          <div className='d-flex gap-1'>
            <Button type='submit' disabled={disableLoading} color='edit' className='btn-sm' onClick={handleDisableJob}>
              Disable job
              {disableLoading && <Spinner className='spinner' />}
            </Button>
            <Button type='button' color='cancel' className='btn-sm'>
              Run Now
            </Button>
          </div>
        )}
      </div>
      <div className='d-flex gap-3'>
        <span className={style.label}>Job Name:</span>
        <span className={style.value}>{cronJobData.name}</span>
      </div>

      <h5 className={style.detail}>Detail</h5>
      <div className='mt-2'>
        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Time Started:</span>
          <span className={style.value}>
            {moment.utc(cronJobData.startedAt).local(false).format('MMMM Do YYYY, h:mm:ss a')}
          </span>
        </div>
        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Time Ended:</span>
          <span className={style.value}>
            {moment.utc(cronJobData.endedAt).local(false).format('MMMM Do YYYY, h:mm:ss a')}
          </span>
        </div>
        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Duration:</span>
          <span className={style.value}>{duration}</span>
        </div>
        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Runner:</span>
          <span className={style.value}>{jobDetail?.runner}</span>
        </div>

        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Handler:</span>
          <span className={style.value}>{cronJobData.jobHandlerId}</span>
        </div>
      </div>
    </div>
  )
}

export default CronJobDetail
